import * as React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import PageBody from "../components/ui/pageBody";
import Heading from "../components/ui/heading";
import Button from "./ui/button";

interface IService {
  discription: string;
  title: string;
  image: string;
}
interface Props {
  services: IService[];
}

interface State {}

const BodyContainer = styled.div`
  max-height: 350px;
`;
const Row = styled.div`
  @media only screen and (max-width: 768px) {
    height: auto;
  }
`;

class ServicesList extends React.Component<Props, State> {
  public render() {
    return (
      <div>
        {this.props.services.map((service, i) => (
          <Row className="flex flex-wrap">
            <div className="w-full xl:w-1/2 px-5 xl:px-10 lg: py-10 bg-gray-200">
              <Heading text={service.title} />
              <BodyContainer className="oveflow-hidden overflow-y-auto pr-4">
                <PageBody>
                  <ReactMarkdown>{service.discription}</ReactMarkdown>
                </PageBody>
              </BodyContainer>
              <div className="mt-6">
                <Button
                  link="/contact-us"
                  text="Request Service"
                  color="bg-blue-500"
                  hover="bg-blue-700"
                />
              </div>
            </div>
            <div
              className={
                "w-full xl:w-1/2 bg-gray-200 opacity-75 hover:opacity-100" +
                (i % 2 == 0 ? "" : " xl:order-first")
              }
            >
              <img
                className="object-cover w-full h-full"
                src={`${service.image}`}
              />
            </div>
          </Row>
        ))}
      </div>
    );
  }
}

export default ServicesList;
