import * as React from "react";
import { graphql } from "gatsby";

import Container from "../components/ui/container";
import Section from "../components/ui/section";
import BasicLayout from "../layouts/BasicLayout";
import PageHeader from "../components/ui/pageHeader";
import ServicesList from "../components/servicesList";

class Services extends React.Component {
  render() {
    const { data } = this.props;
    const page = data.page;
    return (
      <div>
        <BasicLayout pageTitle={page.frontmatter.title}>
          <PageHeader
            title={page.frontmatter.title ? page.frontmatter.title : ""}
            image={page.frontmatter.image}
            breadcrumb={[
              {
                page: page.frontmatter.title ? page.frontmatter.title : "",
                path: this.props.uri,
              },
            ]}
          />
          <Section background="bg-white">
            <Container padding>
              {page.frontmatter.items && (
                <ServicesList services={page.frontmatter.items} />
              )}
            </Container>
          </Section>
        </BasicLayout>
      </div>
    );
  }
}

export default Services;

export const ServicesQuery = graphql`
  query ServicesPageQuery {
    page: mdx(frontmatter: { id: { eq: "services" } }) {
      frontmatter {
        title
        image
        items {
          discription
          title
          image
        }
      }
    }
  }
`;
