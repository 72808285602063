import * as React from "react";
import styled from "styled-components";

import Breadcrumb from "./breadcrumb";

interface IBackgroundImage {
  image: string;
}

interface Props {
  title: string;
  image: string;
  breadcrumb: { page: string; path: string }[];
}

interface State {}

const BackgroundImage = styled.div<IBackgroundImage>`
  background-image: url(${(props) => props.image});
`;

const HeaderContent = styled.div`
  height: 50vh;
`;

class PageHeader extends React.Component<Props, State> {
  public render() {
    return (
      <BackgroundImage
        image={this.props.image}
        className="bg-gray-800 xl:bg-fixed text-white bg-cover bg-center border-b-4 border-teal-500"
      >
        <HeaderContent className="flex flex-col justify-center px-10 bg-gray-800 bg-opacity-75">
          <Breadcrumb trail={this.props.breadcrumb} />
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold">
            {this.props.title}
          </h1>
        </HeaderContent>
      </BackgroundImage>
    );
  }
}

export default PageHeader;
